<template>
  <div class="header">
    <b-row>
      <b-col
        cols="12"
        class="mb-1"
      >
        <div class="list list-inline list-category">
          <span
            class="list-inline-item font-medium-1 mr-50"
            :class="{ active: tabActive === 0 }"
            href="#"
            @click="getAllArticles"
          >
            Tất cả
          </span>
          <span
            v-for="item in category"
            :key="item.id"
            class="list-inline-item font-medium-1 mr-50"
            href="#"
            :class="{ active: tabActive === item.id }"
            @click="getArticleByCategory(item)"
          >
            {{ item.name }}
          </span>
        </div>
      </b-col>
      <b-col
        cols="12"
        class="mb-1 d-none d-md-block"
      >
        <b-breadcrumb>
          <b-breadcrumb-item href="#foo">
            Danh mục
          </b-breadcrumb-item>
          <b-breadcrumb-item href="#bar">
            {{ currentCategory }}
          </b-breadcrumb-item>
        </b-breadcrumb>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { getUser } from '@/auth/utils'
export default {
  name: 'University',
  components: {
    BBreadcrumb, BBreadcrumbItem, BRow, BCol,
  },
  data() {
    return {
      newsHot: [],
      newsLatest: [],
      currentCategory: 'Tất cả',
      categoryId: 0,
      tabActive: 0,
      user: getUser(),
    }
  },
  computed: {
    ...mapGetters({
      category: 'article/category',
    }),
  },
  async created() {
    await this.getInitData()
  },
  methods: {
    ...mapActions({
      getCategory: 'article/getCategory',
    }),

    async getInitData() {
      const param = {
        organizationId: this.user.orgId,
      }
      await this.getCategory(param)
    },

    async getAllArticles() {
      this.currentCategory = 'Tất cả'
      this.$emit('changeCategory', 1)
      this.tabActive = 0
    },

    async getArticleByCategory(data) {
      this.currentCategory = data.name
      this.$emit('changeCategory', data.id)
      this.tabActive = data.id
    },
  },
}
</script>

<style scoped>
.list-inline-item {
  color: black;
  cursor: pointer;
}
.list-category .list-inline-item:not(:last-child){
  border-right: 1px solid #7367f0;
  padding-right: 0.5rem;
}
.list-category .list-inline-item.active{
  font-weight: 600;
}
span.list-category {
  margin: 2px;
  color: black;
  border-right: 2px solid #ccc;
  cursor: pointer;
  font-weight: 500;
}
span.list-category:last-child{
  border-right: none;
}

span.list-category:first-child{
  margin-left: 0;
}
.breadcrumb{
  background-color: #ccc;
  display: inline-flex;
}
ol.breadcrumb li.breadcrumb-item a {
  color: black;
  font-weight: 700;
}
.active {
  color: red;
  border-bottom: 1px solid red;
  font-weight: bold;
}
</style>
