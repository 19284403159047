import { render, staticRenderFns } from "./University.vue?vue&type=template&id=b35f524e"
import script from "./University.vue?vue&type=script&lang=js"
export * from "./University.vue?vue&type=script&lang=js"
import style0 from "./University.vue?vue&type=style&index=0&id=b35f524e&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports